// ------------------------------------
// bootstrap-sass/assets/stylesheets/bootstrap
// ------------------------------------

// Core variables and mixins
@import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "~bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/print";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/type";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/grid";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "~bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/navbar";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/pagination";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/pager";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/alerts";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/media";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/list-group";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/wells";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/modals";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "~bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";


// ------------------------------------
// CUSTOM CONFIG
// ------------------------------------

$linkColor: #c50000;



// ------------------------------------
// FONT AWESOME
// ------------------------------------

@import "~font-awesome/scss/font-awesome";



// ------------------------------------
// BOOTSTRAP SOCIAL
// ------------------------------------

@import "~bootstrap-social/bootstrap-social";
.btn-youtube { @include btn-social(#ed302f); }
.btn-newsletter { @include btn-social(#4b515d); }



// ------------------------------------
// CUSTOM CODE
// ------------------------------------

// Leave some room for the navbar
body {
  padding-top: $navbar-height;
}

// Change links color
a {
  color: $linkColor;
}

// The logo must fit in the navbar
.navbar-brand {
  padding: 0px 10px;
  .logo {
    height: 32px;
    margin: 8px 0;
  }
}

// Responsive embed for the map
.embed-responsive-20by8 {
  padding-bottom: percentage(calc(8 / 20));
}

// Social buttons row
.social-buttons {
  margin-top: 10px;
  margin-bottom: 20px;
}

// Style for archives thumbs
.archives {
  .ratio {
    width: 100%;
    padding-bottom: 150%; /* % of width, defines aspect ratio*/
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .date {
    display: inline-block;
    margin-top: 10px;
  }
}

// A discreet footer
footer {
  text-align: center;
  color: #aaa;
  font-style: italic;

  a {
    color: desaturate(lighten($linkColor, 25%), 25%);
  }
}

.title-truncate {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
}

// A helper to get some space after a block
.spaced {
  margin-bottom: 15px;
}

// Hides lazy loaded images by default
// They should be made visible by JavaScript
// This way, only images in "noscript" tags are visible if JS is disabled
.lazy {
  display: none;
}

.inline-block {
  display: inline-block;
}

.no-trailer-or-poster {
  .h1 {
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  }

  &:hover {
    text-decoration: none !important;
  }
}
